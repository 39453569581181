import { GetStaticProps } from 'next';
import { useEffect, useState } from 'react';
import MobilePage from 'pages/_mobile/index';
import DesktopPage from 'pages/_desktop/index';

export const getStaticProps: GetStaticProps = async (ctx) => ({
  props: {
    locale: ctx.locale || null,

    // Adjust your config here! This config is used by the _app.tsx to know what behavior to have on each page.
    // All those config variables may not be used yet, check the code to ensure :)
    config: {
      head: {
        faviconURL: '/favicon--32@2x.png',
        title: 'Nextstore: website builder',
        description: 'Nexstore, the website builder that turns your frontend into a conversion-oriented machine',
        twitterCard: 'summary_large_image',
        ogImage: '/meta--full-color@2x.png',
        ogImageAlt: 'Nextstore',
        ogImageHeight: 32,
        ogImageWidth: 32,
      },
      header: {
        //   opaqueOnScroll: false,
        //   disabled: false,
      },
      scroll: {
        //   snap: true,
        //   hideScrollbar: true,
        //   disabled: false,
      },
      cart: {
        //   disabled: false,
      },
      menu: {
        //   disabled: false,
      },
      footer: {
        //   disabled: false,
      },
    },
  },
  revalidate: 3600,
});

const getDeviceType = (): string => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

export default function Page(): JSX.Element | undefined {
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const device = getDeviceType();
    setDeviceType(device);
  }, []);

  if (!deviceType) {
    return undefined;
  }

  if (deviceType === 'mobile') {
    return <MobilePage />;
  }
  if (deviceType === 'desktop' || deviceType === 'tablet') {
    return <DesktopPage />;
  }
}
